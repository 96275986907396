import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { modalSizes, defaultDateFormat } from 'shared/constants';
import { IconPopup } from 'shared/Icons';
import { getLocale } from 'shared/DatePicker/constants';
import { selectModalStyles, selectStyles } from 'styles/modules/reactSelect';
import { Table, Modal, Button, TableButtons, ConfirmationModal } from 'shared';
import {
  addNewDocumentType,
  addWorkerDocument,
  deleteDocumentType,
  deleteWorkerDocument,
  editDocumentType,
  getAllDocumentTypes,
  getWorkerDocuments,
  getWorkerDocumentsPaginated } from '../../actions';

const ManageDocumentsModal = ({ isReadOnly, t, showDocumentationModal, handleClose, companyId, currentUser, workerId, refetchDocumentTypes, currentUserRole }) => {
  const [addDocument, setAddDocument] = useState(false);
  const [addDocumentType, setAddDocumentType] = useState(false);
  const [documentExpiry, setDocumentExpiry] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [documentTypeData, setDocumentTypeData] = useState({});
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [allDocumentTypes, setAllDocumentTypes] = useState([]);
  const [showMissingDocumentError, setShowMissingDocumentError] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [documentsTableData, setDocumentsTableData] = useState({
    documents: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });

  const fetchAllDocumentTypes = async () => {
    let filters = '';

    if (currentUserRole !== 'HR Specialist' && currentUserRole !== 'HR Admin' && !currentUser.is_admin) {
      filters += '&is_mid=false';
    }
    const resp = await getAllDocumentTypes(companyId, filters);
    setAllDocumentTypes(get(resp, 'data.results', []));
  };

  // const fetchDocumentTypesPagination = async (url) => {
  //   setDocumentTypesTableData((prevState) => ({
  //     ...prevState,
  //     isLoading: true,
  //   }));
  //   const resp = await getDocumentTypesPaginated(url);
  //   setDocumentTypesTableData({
  //     documentTypes: get(resp, 'data.results', []),
  //     next: get(resp, 'data.next', []),
  //     previous: get(resp, 'data.previous', []),
  //     count: get(resp, 'data.count', []),
  //     isLoading: false,
  //   });
  // };

  const fetchWorkerDocuments = async () => {
    setDocumentsTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let filters = '&order_by=-created_at';

    if (typeFilter) {
      filters += `&document_type=${typeFilter}`;
    }
    if (currentUserRole !== 'HR Specialist' && currentUserRole !== 'HR Admin' && !currentUser.is_admin) {
      filters += '&is_mid=false';
    }
    const resp = await getWorkerDocuments(companyId, workerId, 30, filters);
    setDocumentsTableData({
      documents: get(resp, 'data.results', []),
      next: get(resp, 'data.next', []),
      previous: get(resp, 'data.previous', []),
      count: get(resp, 'data.count', []),
      isLoading: false,
    });
  };

  const fetchWorkerDocumentsPaginated = async (url) => {
    setDocumentsTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const resp = await getWorkerDocumentsPaginated(url);
    setDocumentsTableData({
      documents: get(resp, 'data.results', []),
      next: get(resp, 'data.next', []),
      previous: get(resp, 'data.previous', []),
      count: get(resp, 'data.count', []),
      isLoading: false,
    });
  };

  const clearStateValues = () => {
    setSelectedDocument(null);
    setSelectedDocumentType(null);
    setDocumentExpiry(null);
    setDocumentTypeData({});
    setShowMissingDocumentError(false);
  };

  const handleSaveDocument = async () => {
    if (!selectedDocument || !selectedDocument?.name || !selectedDocumentType?.id) {
      setShowMissingDocumentError(true);
      return;
    }
    if (selectedDocument) {
      setIsSaving(true);
      const formData = new FormData();
      formData.append('file', selectedDocument);
      formData.append('document_type', selectedDocumentType.id);
      formData.append('worker', workerId);
      if (documentExpiry) formData.append('valid_until', new Date(documentExpiry).toISOString());
      formData.append('name', selectedDocument.name);
      formData.append('user', currentUser.id);
      await addWorkerDocument(formData);
      setIsSaving(false);
      fetchWorkerDocuments();
      setAddDocument(false);
      clearStateValues();
      refetchDocumentTypes();
    }
  };

  useEffect(() => {
    fetchAllDocumentTypes();
  }, []);

  useEffect(() => {
    fetchWorkerDocuments();
  }, [typeFilter]);

  const handleAddDocumentType = async () => {
    setIsSaving(true);
    const data = {
      company: companyId,
      name: documentTypeData.name,
      // time_span: documentTypeData.time_span,
      is_mid: documentTypeData.is_mid,
      user: currentUser?.id };
    if (documentTypeData?.id) {
      await editDocumentType(data, documentTypeData?.id, companyId);
    } else {
      await addNewDocumentType(data);
    }
    setIsSaving(false);
    setAddDocumentType(false);
    fetchAllDocumentTypes();
  };

  const handleChangeInput = (val, key) => {
    setDocumentTypeData((prevData) => ({ ...prevData, [key]: val }));
  };

  const handleDownloadDocument = (row) => {
    const link = document.createElement('a');
    link.href = row.file;
    link.target = '_blank';
    link.setAttribute('download', row.name);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const onChangeAttachments = (e) => {
    setSelectedDocument(e.target.files[0], 'profile_picture');
  };

  const handleCloseModal = () => {
    clearStateValues();
    handleClose();
  };

  const handleBackButton = () => {
    setAddDocument(false);
    clearStateValues();
  };

  const handleBackButtonType = () => {
    setAddDocumentType(false);
    setDocumentTypeData(null);
    clearStateValues();
  };

  const handleRowClick = (row) => {
    setDocumentTypeData(row);
    setAddDocumentType(true);
  };

  const handleShowConfirmationModal = (row, e, type) => {
    if (e) e.stopPropagation();
    const data = row;
    data.type = type;
    setDeleteData(data);
    setShowConfirmationDialog(true);
  };

  const handleDelete = async () => {
    const id = deleteData.id;
    if (deleteData.type === 'document') {
      await deleteWorkerDocument(companyId, id);
      await fetchWorkerDocuments();
      refetchDocumentTypes();
    }
    if (deleteData.type === 'type') {
      await deleteDocumentType(id, companyId);
      await fetchAllDocumentTypes();
    }
    setShowConfirmationDialog(false);
  };

  const tableColumnConfig = [
    {
      Header: () => <span>{t('page_content.workforce_management.documents_modal.table_column_created_at')}</span>,
      accessor: 'created_at',
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.documents_modal.table_column_name')}</span>,
      accessor: 'name',
      Cell: (row) => (row.value ? row.value : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.documents_modal.table_column_type')}</span>,
      accessor: 'document_type.name',
      Cell: (row) => (row.value ? row.value : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.documents_modal.table_column_valid_until')}</span>,
      accessor: 'valid_until',
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.documents_modal.table_column_open')}</span>,
      width: 100,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
        <div onClick={() => handleDownloadDocument(row.original)}>
            <Button
              style={{ width: 55, padding: '5px 8px' }}
            >
                <IconPopup
                  height="14px"
                  width="14px"
                  color="#555"
                />
            </Button>
        </div>
      </div>),
      style: { cursor: 'default' },
    },
  ];

  const tableColumnConfigType = [
    {
      Header: () => <span>{t('page_content.workforce_management.documents_modal.table_column_type')}</span>,
      accessor: 'name',
      Cell: (row) => (row.value ? row.value : '-'),
      style: { cursor: 'default' },
    },
  ];

  const handleChangeTab = () => {
    setShowMissingDocumentError(false);
  };

  return (
    <Modal
      size={modalSizes.large}
      title={t('page_content.workforce_management.documents_modal.all_documents')}
      isOpen={showDocumentationModal}
      handleClose={handleCloseModal}
      error={showMissingDocumentError && t('page_content.workforce_management.documents_modal.error_msg')}
    >
        <div className="worker_details_tabContainer">
          <Tabs onSelect={handleChangeTab}>

            <TabList>
              <Tab>{t('page_content.workforce_management.documents_modal.all_documents')}</Tab>
              {(currentUserRole === 'HR' || !isReadOnly) && <Tab>{t('page_content.workforce_management.documents_modal.document_types')}</Tab>}
            </TabList>

            <TabPanel>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {
                  !addDocument &&
                    <Select
                      options={allDocumentTypes}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isClearable
                      menuPosition="fixed"
                      placeholder={t('page_content.workforce_management.documents_modal.all_types')}
                      onChange={(opt) => setTypeFilter(opt?.id || null)}
                      value={(allDocumentTypes?.find((dT) => dT.id === typeFilter)) || ''}
                      styles={selectStyles}
                    />
                }
                {
                  addDocument &&
                    <div style={{ paddingBottom: '10px' }}>
                      <Button onClick={handleBackButton}>{t('page_content.workforce_management.documents_modal.back_button')}</Button>
                    </div>
                }
                {
                  (!addDocument && (currentUserRole === 'HR' || !isReadOnly)) &&
                    <div>
                      <Button type="add" onClick={() => setAddDocument(true)}>{t('page_content.workforce_management.documents_modal.add_document')}</Button>
                    </div>
                }
              </div>
              {addDocument ?
                <div className="inputs-column">
                  <div className="input_container_documents">
                    <div className="left_text">{t('page_content.workforce_management.documents_modal.label_document_type')}: *</div>
                    <Select
                      className="right_select"
                      options={allDocumentTypes}
                      getOptionLabel={(type) => type.name}
                      getOptionValue={(type) => type.id}
                      menuPosition="fixed"
                      placeholder={t('page_content.workforce_management.documents_modal.add_document_placeholder_type')}
                      onChange={(val) => setSelectedDocumentType(val)}
                      isSearchable
                      value={selectedDocumentType}
                      styles={selectModalStyles}
                    />
                  </div>
                  <div className="input_container_documents">
                    <div className="left_text">{t('page_content.workforce_management.documents_modal.add_document_label_expiry')}:</div>
                    <div className="right_input">
                      <DatePicker
                        popperProps={{ strategy: 'fixed' }}
                        className="datepicker_input"
                        dateFormat="dd.MM.yyyy"
                        selected={documentExpiry || null}
                        onChange={(date) => { setDocumentExpiry(date); }}
                        minDate={new Date()}
                        timeFormat="HH:mm"
                        locale={getLocale(t)}
                      />
                    </div>
                  </div>
                  <div className="input_container_documents">
                    <div className="left_text">{t('page_content.workforce_management.documents_modal.add_document_label_choose')}: *</div>
                    <div className="right_input">
                    <input type="file" onChange={onChangeAttachments} />
                    </div>
                  </div>
                  {selectedDocument &&
                  <div className="input_container_documents">
                    <div className="left_text">{t('page_content.workforce_management.documents_modal.selected_document_label')}: </div>
                    <div className="right_input">
                        <span>{selectedDocument?.name}</span>
                    </div>
                  </div>}
                  <div className="add_button">
                    <Button
                      type="add"
                      onClick={handleSaveDocument}
                      disabled={!selectedDocumentType || !selectedDocument || isSaving}
                    >
                      {t('page_content.workforce_management.documents_modal.add_document_save_button')}
                    </Button>
                  </div>
                </div> :
                <div>
                  <Table
                    style={{ userSelect: 'text', marginTop: 20 }}
                    columns={tableColumnConfig}
                    data={documentsTableData?.documents || []}
                    minRows={0}
                    defaultPageSize={30}
                    noDataText=" "
                    sortable
                    loading={documentsTableData.isLoading}
                    showPagination={false}
                    selectedRow={null}
                    manual={false}
                    enableDelete
                    onDelete={(original, e) => handleShowConfirmationModal(original, e, 'document')}
                    isActionsDisabled={(currentUserRole === 'HR' ? false : isReadOnly)}
                    getTrProps={(state, rowInfo) => {
                      const validUntilDate = moment(rowInfo?.original?.valid_until);
                      const today = moment();

                      let backgroundColor = null;

                      if (validUntilDate.isSameOrBefore(today)) backgroundColor = '#ffcccc';

                      return {
                        style: { backgroundColor },
                      };
                    }}
                  />
                  <TableButtons
                    style={{ marginBottom: 10 }}
                    next={documentsTableData.next}
                    previous={documentsTableData.previous}
                    count={documentsTableData.count}
                    fetchFunction={fetchWorkerDocumentsPaginated}
                  />
                </div>}
            </TabPanel>

            {
              (currentUserRole === 'HR' || !isReadOnly) &&
              <TabPanel>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  {addDocumentType &&
                    <Button onClick={handleBackButtonType}>
                      {t('page_content.workforce_management.documents_modal.back_button')}
                    </Button>}
                </div>
                <div>
                  {!addDocumentType &&
                    <Button type="add" onClick={() => setAddDocumentType(true)}>
                      {t('page_content.workforce_management.documents_modal.add_document_type')}
                    </Button>}
                </div>
              </div>
              {addDocumentType ?
                <div className="inputs-column">
                  <div className="input_container_documents">
                    <div className="left_text">{t('page_content.workforce_management.documents_modal.label_document_type')}: *</div>
                    <div className="right_input">
                      <input
                        type="text"
                        value={documentTypeData.name}
                        onChange={(e) => handleChangeInput(e.target.value, 'name')}
                      /></div>
                  </div>
                  {(currentUserRole === 'HR Specialist' || currentUserRole === 'HR Admin' || currentUser.is_admin) && (
                    <div className="input_container_documents">
                      <div className="left_text">
                        {t('page_content.workforce_management.documents_modal.label_is_contract')}:
                        </div>
                        <div className="right_input">
                          <input
                            type="checkbox"
                            checked={documentTypeData.is_mid}
                            onChange={(e) => handleChangeInput(e.target.checked, 'is_mid')}
                          />
                            </div>
                            </div>
                  )}
                  <div className="add_button">
                    <Button type="add" disabled={!documentTypeData?.name || isSaving} onClick={handleAddDocumentType}>
                      {documentTypeData?.id ?
                        t('page_content.workforce_management.documents_modal.update_document_type') :
                        t('page_content.workforce_management.documents_modal.add_document_type')}</Button>
                  </div>
                </div>
                :
                <div>
                <Table
                  style={{ userSelect: 'text', marginTop: 20 }}
                  columns={tableColumnConfigType}
                  data={allDocumentTypes || []}
                  minRows={0}
                  defaultPageSize={15}
                  noDataText=" "
                  sortable
                  showPagination
                  enableEdit
                  enableDelete
                  onEdit={(original) => handleRowClick(original)}
                  onDelete={(original, e) => handleShowConfirmationModal(original, e, 'type')}
                  isActionsDisabled={(currentUserRole === 'HR' ? false : isReadOnly)}
                  selectedRow={null}
                  manual={false}
                />
                </div>}
            </TabPanel>
            }
          </Tabs>
        </div>
        <ConfirmationModal
          itemName={deleteData?.name || ''}
          showModal={showConfirmationDialog}
          handleCloseModal={() => setShowConfirmationDialog(false)}
          handleConfirmModal={handleDelete}
          type="warning"
        />
      </Modal>
  );
};

ManageDocumentsModal.propTypes = {
  workerId: PropTypes.number,
  isReadOnly: PropTypes.bool,
  companyId: PropTypes.number,
  handleClose: PropTypes.func,
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  refetchDocumentTypes: PropTypes.func,
  showDocumentationModal: PropTypes.bool,
  currentUserRole: PropTypes.string.isRequired,
};

export default (withRouter(withTranslation()(ManageDocumentsModal)));
